import { createSlice } from '../../utils/npm'

const ipoDetailSlice = createSlice({
  name: 'ipoDetailSlice',
  initialState: {
    data: {},
    loadingDetail: false,
    error: null
  },
  reducers: {
    setIpoDetail: (state, action) => {
      state.data = action.payload
    },
    startLoadingDetail: state => {
      state.loadingDetail = true
    },
    stopLoadingDetail: state => {
      state.loading = false
    },
    setErrorDetail: (state, action) => {
      state.error = action.payload
    },
    clearErrorDetail: state => {
      state.error = null
    }
  }
})

export const {
  setIpoDetail,
  startLoadingDetail,
  stopLoadingDetail,
  setErrorDetail,
  clearErrorDetail
} = ipoDetailSlice.actions

export default ipoDetailSlice.reducer
