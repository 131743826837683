import { createSlice } from '../../utils/npm'

const ipoDataSlice = createSlice({
  name: 'ipoDataSlice',
  initialState: {
    data: [],
    ipoCategory: localStorage.getItem('ipoCategory') || 'live',
    ipotype: localStorage.getItem('ipoType') || 'mainboard',
    loading: false,
    error: null,
  },
  reducers: {
    setIpoData: (state, action) => {
      state.data = action.payload
    },
    setIpoCategory: (state, action) => {
      state.ipoCategory = action.payload
      localStorage.setItem('ipoCategory', action.payload)
    },
    setIpoType: (state, action) => {
      state.ipotype = action.payload
      localStorage.setItem('ipoType', action.payload) 
    },
    setTypeFilter: (state, action) => {
      state.typeFilter = action.payload
    },
    setStatusFilter: (state, action) => {
      state.statusFilter = action.payload
    },
    startLoading: state => {
      state.loading = true
    },
    stopLoading: state => {
      state.loading = false
    },
    setError: (state, action) => {
      state.error = action.payload 
    },
    clearError: state => {
      state.error = null 
    }
  }
})

export const {
  setIpoData,
  setIpoCategory,
  setIpoType,
  setTypeFilter,
  setStatusFilter,
  startLoading,
  stopLoading,
  setError,
  clearError
} = ipoDataSlice.actions

export default ipoDataSlice.reducer
