
import { routerLinks } from './routes/AppRoutes'
import { Components, RouterProvider, Suspense, store, Provider } from './utils/npm'


const { Loader } = Components

function App () {
  return (
    <Provider store={store}>
    <Suspense
      fallback={
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Loader />
        </div>
      }
    >
      <RouterProvider
        router={routerLinks}
        future={{
          v7_startTransition: true,
        }}
      />
    </Suspense>
  </Provider>
  )
}

export default App
