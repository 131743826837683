// import {axiosInstance} from "../utils/npm"

// export const getIpoList = async (data) => {
//   try {
//     const response = await axiosInstance.post(`/ipo/ipo_list?ipo_type=${data.ipo_type}&category=${data.category}`);
//     return response;
//   } catch (error) {
//     console.error('Failed to fetch IPO list:', error);
//     return error;
//   }
// };

// export const getIpoDetail = async (id) => {
//   try {
//     const response = await axiosInstance.get(`/ipo/${id}`);
//     return response;
//   } catch (error) {
//     console.error('Failed to fetch IPO list:', error);
//     return error;
//   }
// };

import { axiosInstance, axios } from '../utils/npm'

export const getIpoList = async (data) => {
  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `https://production.api.ipotrending.com/ipo/ipo_list?ipo_type=${data.ipo_type}&category=${data.category}`,
    headers: {},
    data: null, 
  };

  try {
    const response = await axios.request(config);
    return response.data; 
  } catch (error) {
 
    console.error('Failed to fetch IPO list:', error);
    throw error; 
  }
};
// export const getIpoDetail = async id => {
//   let config = {
//     method: 'get',
//     maxBodyLength: Infinity,
//     url: `https://production.api.ipotrending.com/ipo/${id}`,
//     headers: {}
//   }
//   axios
//     .request(config)
//     .then(response => {
//       console.log(response);
      
//       return response
//     })
//     .catch(error => {
//       return error
//     })
// }

export const getIpoDetail = async (id) => {
  const config = {
    method: 'get',
    maxBodyLength: Infinity, // In case the response size is large
    url: `https://production.api.ipotrending.com/ipo/${id}`,
    headers: {},
  };

  try {
    const response = await axios.request(config);
    console.log('API Response:', response.data); 
    return response.data; 
  } catch (error) {
    console.error('Failed to fetch IPO detail:', error);
    return error;
  }
};


export const getNewsList = async (id) => {
  const config = {
    method: 'get',
    maxBodyLength: Infinity, 
    url: `https://production.api.ipotrending.com/news`,
    headers: {},
  };

  try {
    const response = await axios.request(config);
    return response.data; 
  } catch (error) {
    console.error('Failed to fetch IPO detail:', error);
    return error;
  }
};
