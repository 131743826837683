// REACT IMPORTS
import React, {
  lazy,
  Suspense,
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react' // Import React and Suspense for lazy loading

// REACT ROUTER IMPORTS
import { createBrowserRouter, RouterProvider, Link, useNavigate, useParams  } from 'react-router-dom' // Essential Router components

// STYLES IMPORTS
import '../App.css'
import '../assets/css/loader.css'
import '../assets/css/navbar.css'
import '../assets/css/homepage.css'
import '../assets/css/footer.css'

//images

//header
import logo from '../assets/images/logo.png'
import downArrow from '../assets/images/homepage/down.png'

// homepage
import heroImage from '../assets/images/homepage/hero_image.png'
import currentIpo from '../assets/images/homepage/current_ipo.svg'
import selectIcon from '../assets/images/homepage/select_icon.png'
import upcomingIpo from '../assets/images/homepage/upcoming_ipo.svg'
import listedIpo from '../assets/images/homepage/listed_ipo.svg'
import hyundaiIpo from '../assets/images/homepage/hyundai-india-logo.jpg.png'
import WaareeIpo from '../assets/images/homepage/Waaree.png.png'
import swiggyIpo from '../assets/images/homepage/swiggy-logo.png.png'
import newsHealth from '../assets/images/homepage/newshealth.png'
import newsJio from '../assets/images/homepage/newsjio.png'
import newsrelianceJio from '../assets/images/homepage/newsrelianceJio.png'
import newsRelliance from '../assets/images/homepage/newsrelliance.png'
import downNews from '../assets/images/homepage/down_news.svg'
import appStore from '../assets/images/homepage/appStore.png'
import playStore from '../assets/images/homepage/playStore.png'
import twoMobile from '../assets/images/homepage/twoMobile.png'
import brokerKotak from '../assets/images/homepage/brokerKotak.png'
import brokerdhan from '../assets/images/homepage/brokerdhan.png'
import brokerzerodha from '../assets/images/homepage/brokerzerodha.png'
import brokericici from '../assets/images/homepage/brokericici.jpg'
import brokerAngel from '../assets/images/homepage/brokerAngel.png'
import brokerpaisa5 from '../assets/images/homepage/broker5paisa.png'
import brokergroww from '../assets/images/homepage/brokerGroww.webp'
import brokerRight from '../assets/images/homepage/broker_right.png'
import aboutUsHome from '../assets/images/homepage/aboutUsHome.png'
import getInTouchImg from '../assets/images/homepage/getInTouch.png'
import facebook from '../assets/images/footer/Link - facebook.png.png'
import Instagram from '../assets/images/footer/Link - Instagram Profile.png'
import Telegram from '../assets/images/footer/Link - Telegram Profile.png'
import Youtube from '../assets/images/footer/Link - Youtube Channel.png'
import twitter from '../assets/images/footer/Link - twitter.png.png'
import Whatsapp from '../assets/images/footer/Link - Whatsapp Profile.png'
import { IoCaretDownSharp } from 'react-icons/io5'

// mui items
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'

//icons
import { RiMenuAddLine } from 'react-icons/ri'
import { FaPlus } from 'react-icons/fa6'
import { FaMinus } from 'react-icons/fa6'

// store
import { configureStore, createSlice } from '@reduxjs/toolkit'
import { useDispatch, useSelector, Provider } from 'react-redux'
import axios from 'axios'
import {
  setIpoType,
  setIpoCategory,
  startLoading,
  stopLoading,
  setIpoData,
  setError,
  clearError
} from '../store/slice/ipoDataSlice'
import store from '../store/store'

// apis
import { getIpoList, getIpoDetail, getNewsList } from '../api/IposApi'
import axiosInstance from '../api/axiosInstance'

//library form
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import {
  getInTouchSchema,
  getInTouchFormFields,
  newsLetterSchema,
  newsLetterFormFields
} from '../components/FormSchema'

// COMPONENTS (Lazy-loaded)
const Header = lazy(() => import('../components/Header'))
const Footer = lazy(() => import('../components/Footer'))
const Loader = lazy(() => import('../components/Loader'))
const Button = lazy(() => import('../uiComponents/Button'))
const IpoList = lazy(() => import('../components/IpoList'))
const SelectMainBoard = lazy(() => import('../components/SelectMainBoard'))
const Tags = lazy(() => import('../components/Tags'))
const IpoNewsList = lazy(() => import('../components/IpoNewsList'))
const TopBrokersList = lazy(() => import('../components/TopBrokersList'))
const Accordian = lazy(() => import('../components/Accordian'))
const GetInTouchForm = lazy(() => import('../components/GetInTouchForm'))
const Input = lazy(() => import('../components/Input'))
const TextArea = lazy(() => import('../components/TextArea'))
const NewsLetter = lazy(() => import('../components/NewsLetter'))
const Brokers = lazy(() => import('../components/Brokers'))
const IPOList2 = lazy(() => import('../components/IPOList2'))
const FilterListingType = lazy(() => import('../components/FilterListingType'))
const TabsList = lazy(() => import('../components/TabsList'))
const IpoDetail = lazy(() => import('../components/IpoDetail'))

//pages
const HomePage = lazy(() => import('../pages/HomePage'))

// EXPORTS
// Exporting all necessary React and Router components
export {
  React,
  lazy,
  Suspense,
  createBrowserRouter,
  RouterProvider,
  Link,
  useCallback,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useState,
  useEffect,
  useRef,
  z,
  zodResolver,
  useForm,
  getInTouchSchema,
  getInTouchFormFields,
  newsLetterSchema,
  newsLetterFormFields,
  configureStore,
  axios,
  setIpoType,
  setIpoCategory,
  startLoading,
  stopLoading,
  useDispatch,
  useSelector,
  createSlice,
  store,
  Provider,
  getIpoList,
  setIpoData,
  setError,
  clearError,
  axiosInstance,
  useNavigate,
  useParams,
  getIpoDetail,
  getNewsList
}

// Exporting all lazy-loaded components together
export const Components = {
  Header,
  Footer,
  HomePage,
  Loader,
  Button,
  IpoList,
  SelectMainBoard,
  Tags,
  IpoNewsList,
  TopBrokersList,
  Accordian,
  GetInTouchForm,
  Input,
  TextArea,
  NewsLetter,
  Brokers,
  IPOList2,
  FilterListingType,
  TabsList,
  IpoDetail
}

export const Images = {
  logo,
  downArrow,
  heroImage,
  currentIpo,
  selectIcon,
  upcomingIpo,
  listedIpo,
  hyundaiIpo,
  WaareeIpo,
  swiggyIpo,
  newsHealth,
  newsJio,
  newsrelianceJio,
  newsRelliance,
  downNews,
  appStore,
  playStore,
  twoMobile,
  brokerKotak,
  brokerdhan,
  brokerzerodha,
  brokericici,
  brokerAngel,
  brokerpaisa5,
  brokergroww,
  brokerRight,
  aboutUsHome,
  getInTouchImg,
  facebook,
  Instagram,
  Telegram,
  Youtube,
  twitter,
  Whatsapp,
  IoCaretDownSharp
}

export const icons = {
  RiMenuAddLine,
  FaPlus,
  FaMinus
}
