import { z } from '../utils/npm'

export const getInTouchSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  email: z.string().email('Invalid email format'),
  message: z.string().min(1, 'Message is required')
})

export const getInTouchFormFields = [
  {
    name: 'name',
    type: 'text',
    placeholder: 'Enter your name',
    component: 'Input',
    classList: 'border border-slate-500 input_field custom_name_class'
  },
  {
    name: 'email',
    type: 'email',
    placeholder: 'Enter your email',
    component: 'Input',
    classList:
      'border border-slate-500 input_field input_email custom_email_class'
  },
  {
    name: 'message',
    placeholder: 'Type your message here',
    component: 'TextArea',
    rows: 10,
    classList:
      'border border-slate-500 input_field w-full input_field_textarea overflow-x-hidden custom_message_class'
  }
]

export const newsLetterSchema = z.object({
  email: z.string().email('Invalid email format')
})

export const newsLetterFormFields = [
  {
    name: 'email',
    type: 'email',
    placeholder: 'Enter your email',
    component: 'Input',
    classList: 'email-input' }
]
