import { configureStore } from '../utils/npm'
import ipoDataReducer from './slice/ipoDataSlice'
import ipoDetailReducer from './slice/ipoDetailSlice'
const store = configureStore({
  reducer: {
    ipoData: ipoDataReducer,
    ipoDetail: ipoDetailReducer
  }
})

export default store
