import { createBrowserRouter } from 'react-router-dom';
import { Components } from '../utils/npm';

const { Header, Footer, HomePage, IpoDetail } = Components;

const Layout = ({ children }) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
);

export const routerLinks = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <HomePage />
      </Layout>
    ),
  },
  {
    path: "/company/:id",
    element: (
      <Layout>
        <IpoDetail />
      </Layout>
    ),
  },
], {
  future: {
    v7_fetcherPersist: true,
    v7_startTransition: true,
    v7_relativeSplatPath: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true,
  },
});
